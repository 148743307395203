<template>
  <main class="home">
    <section class="hero position-relative">
      <img
        class="img-fluid position-absolute top-0 start-0"
        src="../assets/image/hero-bg.webp"
        alt="Background"
      />
      <div class="container">
        <div class="row">
          <div class="col-12 col-md-6 col-lg-6 d-flex">
            <div class="container content">
              <h1 class="heroTitle">
                <span>Where AI Meets</span>
                <span class="primary-color"> Human Intelligence</span>
              </h1>
              <div class="sub-content">
                <p class="text-white">
                  We combine creativity, marketing and AI to give you the best
                  results!
                </p>
                <div class="btn-wrapper">
                  <!-- <button class="btn btn-primary me-2 fw-bold fs-6 text-uppercase" type="button">GENERATE</button> -->
                  <button
                    class="btn btn-outline-primary fw-bold fs-6 text-uppercase" id="plan_btn"
                    type="button"
                  >
                    <a
                      href="#subscriptions_sec"
                      class="text-decoration-none text-white"
                      >CHECK OUR PLANS</a
                    >
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div class="col-12 col-md-6 col-lg-6">
            <div class="img-wrapper d-flex">
              <img
                class="heroSideImage img-fluid"
                src="../assets/image/Heroimage.webp"
                alt="Hero Side Image"
              />
            </div>
          </div>
        </div>
      </div>
    </section>
    <div class="container">
      <section class="creative-wrapper">
        <div class="container">
          <div class="top-content pt-5 pb-4 mb-2">
            <h3 class="title text-center text-white">How it works</h3>
            <p class="description text-center">
              These cool stories show how human creativity and machine
              intelligence team up to create awesome results in the world of
              marketing!
            </p>
          </div>
          <div class="creative-slider">
            <div class="carousel-item">
              <div class="row">
                <div class="col-12 col-md-3 col-lg-3">
                  <div class="cards-wrapper">
                    <div class="card-item">
                      <h3 class="title">Brief</h3>
                      <p>A set of requirements is given to us by the client.</p>
                      <!-- <img src="../assets/image/slider/Nike-Logo.webp" class="img-fluid logo" alt="slider-logo"> -->
                    </div>
                    <div class="card-item">
                      <h3 class="title">Text</h3>
                      <p>
                        Generated through a natural language processing tool
                        driven by AI technology
                      </p>
                    </div>
                  </div>
                </div>
                <div class="col-12 col-md-6 col-lg-6">
                  <div class="img-wrapper">
                    <img
                      src="../assets/image/slider/vairal-slider-3.webp"
                      class="img-fluid"
                      alt="slider"
                    />
                  </div>
                </div>
                <div class="col-12 col-md-3 col-lg-3">
                  <div class="cards-wrapper">
                    <div class="card-item">
                      <h3 class="title">Image</h3>
                      <p class="description">
                        Generated through AI based Text to image technology
                      </p>
                    </div>
                    <div class="card-item">
                      <h3 class="title">Final</h3>
                      <p class="description">
                        The final is combined together and exported into a final
                        element using an editing tool
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="carousel-item">
              <div class="row">
                <div class="col-12 col-md-3 col-lg-3">
                  <div class="cards-wrapper">
                    <div class="card-item">
                      <h3 class="title">Brief</h3>
                      <p>A set of requirements is given to us by the client.</p>
                    </div>
                    <div class="card-item">
                      <h3 class="title">Text</h3>
                      <p>
                        Generated through a natural language processing tool
                        driven by AI technology
                      </p>
                    </div>
                  </div>
                </div>
                <div class="col-12 col-md-6 col-lg-6">
                  <div class="img-wrapper">
                    <img
                      src="../assets/image/slider/vairal-slider-2.webp"
                      class="img-fluid"
                      alt="slider"
                    />
                  </div>
                </div>
                <div class="col-12 col-md-3 col-lg-3">
                  <div class="cards-wrapper">
                    <div class="card-item">
                      <h3 class="title">Image</h3>
                      <p class="description">
                        Generated through AI based Text to image technology
                      </p>
                    </div>
                    <div class="card-item">
                      <h3 class="title">Final</h3>
                      <p class="description">
                        The final is combined together and exported into a final
                        element using an editing tool
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="carousel-item">
              <div class="row">
                <div class="col-12 col-md-3 col-lg-3">
                  <div class="cards-wrapper">
                    <div class="card-item">
                      <h3 class="title">Brief</h3>
                      <p>A set of requirements is given to us by the client.</p>
                    </div>
                    <div class="card-item">
                      <h3 class="title">Text</h3>
                      <p>
                        Generated through a natural language processing tool
                        driven by AI technology
                      </p>
                    </div>
                  </div>
                </div>
                <div class="col-12 col-md-6 col-lg-6">
                  <div class="img-wrapper">
                    <img
                      src="../assets/image/slider/vairal-slider.webp"
                      class="img-fluid"
                      alt="slider"
                    />
                  </div>
                </div>
                <div class="col-12 col-md-3 col-lg-3">
                  <div class="cards-wrapper">
                    <div class="card-item">
                      <h3 class="title">Image</h3>
                      <p class="description">
                        Generated through AI based Text to image technology
                      </p>
                    </div>
                    <div class="card-item">
                      <h3 class="title">Final</h3>
                      <p class="description">
                        The final is combined together and exported into a final
                        element using an editing tool
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section class="bodyContent" id="services">
        <div class="container">
          <h3 class="title text-center text-white">
            <span class="primary-color">Vairal is an AI powered agency</span>
            where prompt engineers meet marketers
          </h3>

          <div class="cardsWrapper">
            <div
              class="row row-cols-1 row-cols-md-2 row-cols-lg-3 g-3 d-flex justify-content-center"
            >
              <div
                v-for="(service, index) in servicesData"
                :key="index"
                class="col"
              >
                <div class="card border border-0 h-100 bg-transparent">
                  <div class="contentWrap card-body">
                    <div class="cardTop">
                      <!-- <p href="#" class="innerDesription mb-0">
                      {{ service.topDescription }}
                    </p> -->
                      <div class="img-wrap">
                        <img
                          v-bind:src="service.imageUrl"
                          class="img-fluid w-100"
                          alt="Service Image"
                        />
                      </div>
                    </div>
                    <div class="cardBottom">
                      <p class="bottom-header fw-bold">{{ service.bottomTitle }}</p>
                      <p class="bottomDescription">
                        {{ service.bottomDescription }}
                      </p>
                      <!-- <a href="#" class="bottomLink btn text-white p-0">Learn more</a> -->
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="bottomBody">
            <div class="d-flex justify-content-center pt-5 pb-2">
              <button
                class="btn btn-outline-primary me-2 fw-bold fs-6"
                type="button"
              >
                Did you know?
              </button>
            </div>
            <h4 class="bottomDescription text-center text-white">
              Organisations using AI for content optimisation experienced a 45%
              increase in content engagement rates.
            </h4>
          </div>
        </div>
      </section>

      <section class="subscriptions" id="subscriptions_sec">
        <div class="container">
          <h3 class="subscriptionsTitle text-center text-white">
            Compare Vairal Plans
          </h3>
          <div class="plans-slider">
            <div v-for="(plan, index) in pricingData" :key="index" class="col" >
              <div class="cardWrap px-3 card mb-4 box-shadow h-100">
                <div class="card-header pb-2 pt-4">
                  <h1 class="my-0 text-center pb-3">{{ plan.title }}</h1>
                </div>
                <div class="card-body">
                  <!-- Group the creativeHeader, contentHeader, and packagePriceItem in a div -->
                  <div>
                    <h6 class="card-title pricing-card-title fw-bold">
                      {{ plan.creativeHeader }}
                    </h6>
                    <ul class="list-unstyled mt-1 mb-4">
                      <li
                        class="list-item pt-2"
                        v-for="(creativeItem, i) in plan.creativeItem"
                        :key="i"
                      >
                        <div
                          class="list-wrapper d-flex justify-content-between"
                        >
                          <div class="list-name">
                            {{ creativeItem.name }}
                          </div>
                          <div class="list-value">
                            {{ creativeItem.value }}
                          </div>
                        </div>
                      </li>
                    </ul>

                    <h6
                      class="card-title pricing-card-title pt-4 fw-bold"
                    >
                      {{ plan.contentHeader }}
                    </h6>
                    <ul class="list-unstyled mt-1 mb-4">
                      <li
                        class="list-item pt-2"
                        v-for="(contentItem, i) in plan.contentItem"
                        :key="i"
                      >
                        <div
                          class="list-wrapper d-flex justify-content-between"
                        >
                          <div class="list-name">
                            {{ contentItem.name }}
                          </div>
                          <div class="list-value">
                            {{ contentItem.value }}
                          </div>
                        </div>
                      </li>
                    </ul>

                    <ul class="list-unstyled mt-1 mb-4">
                      <li
                        class="list-item pt-2"
                        v-for="(
                          packagePriceItem, i
                        ) in plan.packagePriceItem"
                        :key="i"
                      >
                        <div
                          class="list-wrapper d-flex justify-content-between pt-4"
                        >
                          <div class="list-name package-price fw-bold">
                            {{ packagePriceItem.name }}
                          </div>
                          <div class="list-value package-price fw-bold">
                            {{ packagePriceItem.value }}
                          </div>
                        </div>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section class="faqwrapper pb-5" id="faq_section">
        <div class="container">
          <h3 class="faqTitle text-center text-white">How does it work?</h3>

          <div class="accordion" id="faqaccordion">
            <div
              v-for="(item, index) in faq"
              :key="index"
              class="accordion-item"
            >
              <h2 class="accordion-header" :id="'heading' + (index + 1)">
                <button
                  class="accordion-button fw-bold"
                  type="button"
                  :data-bs-toggle="'collapse'"
                  :data-bs-target="'#collapse' + (index + 1)"
                  :aria-expanded="index === 0 ? 'true' : 'false'"
                  :aria-controls="'collapse' + (index + 1)"
                >
                  {{ item.title }}
                </button>
              </h2>
              <div
                :id="'collapse' + (index + 1)"
                class="accordion-collapse collapse"
                :class="{ show: index === 0 }"
                :aria-labelledby="'heading' + (index + 1)"
                :data-bs-parent="'#faqaccordion'"
              >
                <div class="accordion-body" v-html="item.description"></div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section class="contact-us" id="contact_section">
        <div class="container">
          <div class="contact-wrap pt-4">
            <h3 class="subscriptionsTitle text-center text-white">
              Contact Us
            </h3>
            <p class="bottomDescription text-center text-white">
              Tell us what exactly you want and we will bring your ideas to life
            </p>
            <div class="contact-body px-md-4 pt-md-2">
              <div class="row mt-4">
                <div class="col-md-4 pb-3">
                  <div class="form-group">
                    <label for="name" class="pb-2 ps-1">Name</label>
                    <input
                      type="text"
                      id="name"
                      name="name"
                      v-model="formData.name"
                      class="form-control rounded text-white"
                      placeholder="Enter your name"
                      required
                    />
                  </div>
                </div>
                <div class="col-md-4 pb-3">
                  <div class="form-group">
                    <label for="company" class="pb-2 ps-1">Company</label>
                    <input
                      type="text"
                      id="company"
                      name="company"
                      v-model="formData.company"
                      class="form-control rounded text-white"
                      placeholder="Enter your company name"
                      required
                    />
                  </div>
                </div>
                <div class="col-md-4 pb-3">
                  <div class="form-group">
                    <label for="email" class="pb-2 ps-1">Email</label>
                    <input
                      type="email"
                      id="email"
                      name="email"
                      v-model="formData.email"
                      class="form-control rounded text-white"
                      placeholder="Enter your email"
                      required
                    />
                  </div>
                </div>
                <div class="col-md-12">
                  <div class="form-group pt-md-2">
                    <label for="requirements" class="pb-2 ps-1"
                      >Requirements : ( Brief )</label
                    >
                    <textarea
                      id="requirements"
                      name="requirements"
                      v-model="formData.requirements"
                      class="form-control rounded text-white"
                      rows="5"
                      placeholder="Enter your requirement"
                      required
                    ></textarea>
                  </div>
                </div>
              </div>
            </div>
            <div class="contact-btn-wrap text-center pt-4 pb-2">
              <button
                class="contact-btn btn btn-primary text-white" id="contact_btn"
                @click="sendEmail"
              >
              Get your free image
              </button>
              <p v-if="message" class="message pt-3" id="contact_message">{{ message }}</p>
              <p v-if="errorMessage" class="text-danger pt-3">
                {{ errorMessage }}
              </p>
            </div>
            <div class="contact-subject-wrap">
                <p class="contact-subject text-center">Subject to eligibility</p>
            </div>
          </div>
        </div>
      </section>
    </div>
  </main>
</template>

<script>
// @ is an alias to /src
import HeroSlider from "@/components/HeroSlider.vue";

import $ from "jquery";

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "slick-carousel/slick/slick.js";
import axios from "axios";

export default {
  name: "HomeView",
  components: {
    HeroSlider,
  },
  data() {
    return {
      servicesData: [
        {
          topTitle: "Write blog about Chocolate and health",
          topDescription:
            "Contrary to popular belief, chocolate, particularly dark chocolate, can be beneficial for cardiovascular health. Dark chocolate is rich in flavonoids, which are antioxidants ...",
          imageUrl: require("@/assets/image/chatgpt.webp"),
          bottomTitle: "Generating blog articles",
          bottomDescription:
            "By combining the strengths of AI and human intelligence, high-quality blog articles can be produced that are well-informed, persuasive and captivating.",
        },
        {
          topTitle: "Write blog about Chocolate and health",
          topDescription:
            "Contrary to popular belief, chocolate, particularly dark chocolate, can be beneficial for cardiovascular health. Dark chocolate is rich in flavonoids, which are antioxidants ...",
          imageUrl: require("@/assets/image/midjurney.webp"),
          bottomTitle: "Generate social media creatives",
          bottomDescription:
            "AI-powered tools can assist in tasks like language optimisation, suggesting compelling visuals, and even providing real-time sentiment analysis, helping human creators refine their messaging for maximum impact.",
        },
        {
          topTitle: "Write blog about Chocolate and health",
          topDescription:
            "Contrary to popular belief, chocolate, particularly dark chocolate, can be beneficial for cardiovascular health. Dark chocolate is rich in flavonoids, which are antioxidants ...",
          imageUrl: require("@/assets/image/website.webp"),
          bottomTitle: "Generate website content",
          bottomDescription:
            "The collaboration between AI and human intelligence allows for efficient content production, combining data-driven insights with human expression to deliver high-quality content that captivates website visitors.",
        },
      ],
      pricingData: [
        {
          title: "Starter",
          creativeHeader: "Creatives",
          creativeItem: [
            {
              name: "Social media post designs",
              value: "10",
            },
            {
              name: "Story board",
              value: "-",
            },
            {
              name: "Animated videos",
              value: "-",
            },
          ],

          contentHeader: "Content",
          contentItem: [
            {
              name: "Blogs",
              value: "-",
            },
            {
              name: "Post captions",
              value: "10",
            },
          ],

          packagePriceItem: [
            {
              name: "Plan Price",
              value: "$280",
            },
          ],
        },

        {
          title: "Pro",
          creativeHeader: "Creatives",
          creativeItem: [
            {
              name: "Social media post designs",
              value: "15",
            },
            {
              name: "Story board",
              value: "1",
            },
            {
              name: "Animated videos",
              value: "1",
            },
          ],

          contentHeader: "Content",
          contentItem: [
            {
              name: "Blogs",
              value: "2",
            },
            {
              name: "Post captions",
              value: "15",
            },
          ],

          packagePriceItem: [
            {
              name: "Plan Price",
              value: "$800",
            },
          ],
        },
        {
          title: "Pro +",
          creativeHeader: "Creatives",
          creativeItem: [
            {
              name: "Social media post designs",
              value: "20",
            },
            {
              name: "Story board",
              value: "2",
            },
            {
              name: "Animated videos",
              value: "2",
            },
          ],

          contentHeader: "Content",
          contentItem: [
            {
              name: "Blogs",
              value: "4",
            },
            {
              name: "Post captions",
              value: "20",
            },
          ],

          packagePriceItem: [
            {
              name: "Plan Price",
              value: "$1400",
            },
          ],
        },
      ],

      faq: [
        {
          title: "Step 1",
          description:
            "Tell us about you and your brand!",
        },
        {
          title: "Step 2",
          description:
            "Tell us exactly what you want and we will bring your ideas to life. Our talented team will understand and learn your vision, goals and ideas. <br><br>Check out our example brief below;<br><br>Create 10 moments of different age groups using the product (brand) in the style of realistic photography (visual type) to be used on social media and website platforms.<br><br>Visual 1<br>Bunch of university students are enjoying a popular soft drink in front of a university environment. These students should showcase joyful emotions while talking to each other while enjoying the soft drink. The group of students should ideally be of Asian ethnicity. The visual caption should be titled to give the impression that the drink brings joy among the youth.<br><br>Visual 2<br>A group of young adults are hanging out at a beach around a bonfire. The generated image should convey a joyous feeling after a tiring day. These individuals are enjoying soft drinks and BBQ. The group of students should ideally be of Asian ethnicity. The visual caption should be titled to give the impression that the drink brings joy during a chill night out.",
        },
        {
          title: "Step 3",
          description:
            "Using the latest artificial intelligence technology, we will create the main elements for your production.",
        },
        {
          title: "Step 4",
          description:
            "Our skilled creators will take AI-generated content and infuse it with their creativity, rephrasing and combining elements to craft a final masterpiece. With us, you can generate content that matches your unique style and vision.",
        },
        {
          title: "How to Use Generative AI Responsibly",
          description:
            "Copyright laws for AI-generated images are still evolving and differ by country. Currently, we follow the guidance of AI experts backed by major companies like Microsoft, Google, etc. <br><br> It's important to understand that AI-generated images are unique to the prompts provided by the user. Even with identical prompts, the resulting images can vary. While the image output is in the public domain within the AI tool and accessible to other users, the ownership of the image belongs to the prompter, as defined by these AI experts. <br><br> For more information, please refer to the Terms of Service at Midjourney, a widely used AI tool at Vairal. <a href='https://docs.midjourney.com/docs/terms-of-service' style='color: white;''> https://docs.midjourney.com/docs/terms-of-service</a>",
        },
      ],
      formData: {
        name: "",
        company: "",
        email: "",
        requirements: "",
      },
      message: "",
      errorMessage: "",
    };
  },
  mounted() {
    // Initialize Slick Carousel after the component is mounted
    $(".creative-slider").slick({
      slidesToShow: 1,
      autoplay: true,
      autoplaySpeed: 2000,
      dots: true,
      arrows: true,
      // prevArrow: '<button type="button" class="slick-prev">Previous</button>',
      // nextArrow: '<button type="button" class="slick-next">Next</button>',
      responsive: [
        {
          breakpoint: 768,
          settings: {
            slidesToShow: 1,
            arrows: false,
          },
        },
        {
          breakpoint: 992,
          settings: {
            slidesToShow: 1,
          },
        },
      ],
    });

    $(".plans-slider").slick({
      slidesToShow: 3, // Show three cards on desktop
      slidesToScroll: 1,
      autoplay: false,
      autoplaySpeed: 2000,
      loop: false,
      dots: true,
      arrows: true,
      // prevArrow: '<button type="button" class="slick-prev">Previous</button>',
      // nextArrow: '<button type="button" class="slick-next">Next</button>',
      responsive: [
        {
          breakpoint: 768,
          settings: {
            slidesToShow: 1,
            arrows: true,
          },
        },
        {
          breakpoint: 992,
          settings: {
            slidesToShow: 2,
          },
        },
      ],
    });

    
  },
  methods: {
    sendEmail() {
      if (
        !this.formData.name ||
        !this.formData.company ||
        !this.formData.email ||
        !this.formData.requirements
      ) {
        this.errorMessage = "Please fill in all fields before submitting.";
        return; // Stop execution if fields are not filled
      }
      axios
        .post(
          "https://vairal.ai/phpmailer/mailer.php",
          new URLSearchParams(this.formData).toString(),
          {
            headers: {
              "Content-Type": "application/x-www-form-urlencoded",
            },
          }
        )
        .then((response) => {
          // Handle successful response
          console.log(this.formData);
          console.log("------------");
          console.log(response.data);
          this.errorMessage = "";
          this.message = "Email sent successfully";
          // Do any further actions after successful email submission
        })
        .catch((error) => {
          // Handle network error
          console.error(error);
          this.message = "Failed to send email";
          // Display an error message to the user or perform error handling
        });
    },
  },
};
</script>

<style lang="scss" scoped>

.hero {
  background-image: linear-gradient(to bottom right, #040b11, #040b1100);

  .content {
    display: flex;
    justify-content: center;
    flex-direction: column;

    .heroTitle {
      font-size: 66px;
      color: #fff;
      font-family: "Bakbak One", cursive;

      @media only screen and (max-width: 768px) {
        font-size: 28px;
        padding-top: 80px;
      }
    }
  }
  .img-wrapper {
    padding-top: 100px;
    @media only screen and (max-width: 768px) {
      padding-top: 30px;
      padding-bottom: 30px;
      // max-height: 355px;
    }
    .heroSideImage {
      @media only screen and (max-width: 768px) {
        padding-top: 30px;
        padding-bottom: 30px;
        // max-height: 355px;
      }
    }
  }
}

.creative-wrapper {
  .top-content {
    .title {
      font-family: "Bakbak One", cursive;
      font-size: 44px;
      @media only screen and (max-width: 768px) {
        font-size: 26px;
      }
    }
    .description {
      max-width: 760px;
      margin: 0 auto;
    }
  }

  .creative-slider {
    padding-bottom: 30px;
    .carousel-item {
      .cards-wrapper {
        display: flex;
        flex-direction: column;
        height: 100%;
        gap: 20px;
        margin-right: 5px;
        margin-left: 5px;
        .card-item {
          background-color: #d9d9d9;
          height: 100%;
          color: #000;
          text-align: center;
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          padding: 12px;
          border-radius: 22px;

          .title {
            font-size: 18px;
            font-weight: 700;
            font-family: "Manrope", sans-serif;
            padding-bottom: 5px;
          }
          p {
            margin-bottom: 0;
          }
          .description {
            font-size: 16px;
            font-weight: 400;
            font-family: "Manrope", sans-serif;
            // max-width: 172px;
            margin-bottom: 0 !important;
          }

          .logo {
            height: 60px;
          }
        }
      }

      .img-wrapper {
        display: flex;
        justify-content: center;
        align-items: center;
        margin-right: 5px;
        margin-left: 5px;

        img {
          height: 450px;
          object-fit: contain;
          border-radius: 22px;
        }
      }
    }
  }
}

.bodyContent {
  .title {
    font-size: 44px;
    padding-top: 40px;
    padding-bottom: 60px;
    max-width: 654px;
    margin: 0 auto;
    @media only screen and (max-width: 768px) {
      font-size: 26px;
    }
  }
  .cardsWrapper {
    .contentWrap {
      background-color: #05223a;
      border: 1px solid #005499;
      border-radius: 22px;
    }
    .cardTop {
      background-color: #040b11;
      color: #fff;
      border-radius: 22px;
      font-family: "Recursive", sans-serif;
      .img-wrap {
        img {
          border-radius: 22px;
        }
      }
    }
    .cardBottom {
      color: #fff;
      padding: 22px;
      font-family: "Manrope", sans-serif;
      font-size: 20px;
      .bottom-header {
        font-size: 20px;
      }
      .bottomDescription,
      .bottomLink {
        font-family: "Poppins", sans-serif;
        font-size: 16px;
        font-weight: 400;
        transition: all 0.3s;
      }
      .bottomLink:hover {
        opacity: 0.6;
      }
    }
  }
  .bottomBody {
    .bottomDescription {
      max-width: 672px;
      margin: 0 auto;
      font-family: "Manrope", sans-serif;
      padding-bottom: 80px;
      padding-top: 10px;
    }
  }
}

.subscriptions {
  .subscriptionsTitle {
    font-family: "Bakbak One", cursive;
    padding-bottom: 30px;
    font-size: 44px;
    @media only screen and (max-width: 768px) {
      font-size: 26px;
    }
  }

  .plans-slider {
    @media only screen and (max-width: 768px) {
      margin: 0 5px;
    }
    &.slick-slider {
      .slick-prev {
      left: -20px !important;
      }
      .slick-next {
        right: -20px !important;
      }
    }
    
    .slick-slide{
      &:last-child{
        .cardWrap{
          margin-right: 0;
        }
      }
      &:first-child{
        .cardWrap{
          margin-left: 0;
          background-color: #0C3D66;    
        }
      }
      &:nth-child(2){
        .cardWrap{
          background-color: #052B4B;
        }
      }
      &:nth-child(3){
        .cardWrap{
          background-color: #05223A;
        }
      }
    }
  
    .cardWrap {
      border-radius: 22px;
      background-color: #05223a;
      color: #fff;
      margin-right: 10px;
      margin-left: 10px;

      @media only screen and (max-width: 768px ){
        margin-right: 5px;
      margin-left: 5px;
      }

      .card-header {
        border-bottom: unset;
        h1 {
          font-family: "Bakbak One", cursive;
        }
      }
      .card-title {
        font-size: 22px;
      }
      .list-item {
        border-bottom: 1px solid #00579f;
        padding-bottom: 10px;
        .list-name {
          &.package-price {
            font-size: 22px;
          }
        }
        .list-value {
          &.package-price {
            font-size: 22px;
          }
        }
      }
    }
    
  }
}

.faqwrapper {
  .faqTitle {
    font-family: "Bakbak One", cursive;
    padding-bottom: 30px;
    padding-top: 60px;
    font-size: 44px;
    @media only screen and (max-width: 768px) {
      font-size: 26px;
    }
  }
  .accordion-item {
    background-color: #141b22;
    border-radius: 22px;
    button {
      background-color: transparent;
    }
    .accordion-body {
      background-color: transparent;
      .midjourney {
        text-decoration: none;
        color: #fff !important;
      }
    }
  }
}

.contact-us {
  .container {
    .contact-wrap {
      border: 1px solid #00579f;
      border-radius: 22px;
      padding: 30px;
      .subscriptionsTitle {
        font-family: "Bakbak One", cursive;
        padding-top: 30px;
        font-size: 44px;
        @media only screen and (max-width: 768px) {
          font-size: 26px;
        }
      }
      .form-control {
        background-color: #05223a;
        border: 1px solid #00579f;
        &::placeholder {
          color: #fff;
          opacity: 0.5;
        }
      }
      .contact-btn {
        width: 200px;
      }
      .contact-subject-wrap {
        .contact-subject {
          font-size: 12px;
          opacity: 0.6;
        }
      }
    }
  }
}
</style>
